import React from 'react'
import EventsSection from '../components/page/index/eventsSection/eventsSection'
import Layout from '../components/layout/layout'

export default function EventsPage() {
  return (
    <Layout
      meta={{
        title: 'Karma Virtual Book Tour',
        description:
          'Join Live - Each event ticket includes a copy of the book. Sadhguru in conversation with Deepak Chopra, Dr. Mark Hyman, Philip Goldberg, Kunal Nayyar. Each conversation will delve into the many intriguing facets of karma.',
      }}
    >
      <EventsSection variant={'events-page'} />
    </Layout>
  )
}
